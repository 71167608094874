import { FormEvent, ReactNode } from 'react'
import cx from 'classnames'

import Button, { ButtonSize, ButtonVariant } from '@components/buttons/button'

interface AlertProps {
  children: ReactNode
  onClick?: (e: FormEvent) => void
  buttonText?: string
  className?: string
  error?: boolean
  success?: boolean
}

const Alert = ({
  children,
  onClick,
  buttonText,
  className,
  error,
  success,
}: AlertProps) => {
  return (
    <div
      className={cx(
        'p-4 rounded-[3px] text-center border',
        {
          'bg-transparent border-gray-light': !error && !success,
          'text-success bg-success-bg border-success': success,
          'text-error bg-error-bg border-error': error,
        },
        className
      )}
    >
      {children}
      {onClick && buttonText && (
        <div className="mt-4">
          <Button
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.SMALL}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  )
}

export default Alert
