import groq from 'groq'

import { imageFragment } from './image'
import { linkPageFragment } from './link'
import { videoFragment } from './video'
import { buttonStyleFragment } from './link'

export const contentFragment = groq`
  ...,
  markDefs[] {
    ...,
    _type == "link" => {
      url,
      isButton,
      isButton == true => {
        "buttonStyles": buttonStyle {
          ${buttonStyleFragment}
        },
      },
      page->{
        ${linkPageFragment}
      }
    },
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == 'quote' => {
    text,
    author,
    textAlignment
  },
  _type == "videoEmbed" => {
    type,
    youtubeVideoUrl
  },
  _type == "videoButton" => {
    text,
    video {
      ${videoFragment}
    },
    style {
      ${buttonStyleFragment}
    }
  },
  _type == "addToCartButton" => {
    text,
    style {
      ${buttonStyleFragment}
    },
    productVariant->{
      price,
      inStock,
      "id": variantID,
    }
  },
`
