import { useState, ReactNode } from 'react'
import { m, Variants } from 'framer-motion'
import cx from 'classnames'

import Icon from '@components/icon'

const accordionAnimation: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

interface AccordionProps {
  id: string
  title?: ReactNode
  isOpen?: boolean
  isControlled?: boolean
  onToggle?: (id: string, isOpen: boolean) => void
  className?: string
  innerClassName?: string
  children?: ReactNode
}

const Accordion = ({
  id,
  title,
  isOpen = false,
  isControlled = false,
  onToggle = () => null,
  className,
  innerClassName,
  children,
}: AccordionProps) => {
  const [hasFocus, setHasFocus] = useState(isOpen)

  return (
    <div className={cx('border-t border-current', className)}>
      {!isControlled && (
        <button
          onClick={() => onToggle(id, !isOpen)}
          aria-expanded={isOpen}
          aria-controls={`accordion-${id}`}
          className="text-left flex justify-between items-center px-0 py-4 w-full bg-transparent font-semibold text-lg leading-snug"
        >
          {title}
          <div className="ml-2 w-5 h-5">
            <Icon
              id={`accordion-icon-${id}`}
              name="ChevronDown"
              viewBox="0 0 28 28"
              className={cx(
                'transition-transform duration-300 ease-custom-1 text-current h-full',
                {
                  'transform rotate-180': isOpen,
                }
              )}
            />
          </div>
        </button>
      )}

      <m.div
        id={`accordion-${id}`}
        className="overflow-hidden"
        initial={isOpen ? 'open' : 'closed'}
        animate={isOpen ? 'open' : 'closed'}
        variants={accordionAnimation}
        transition={{ duration: 0.5, ease: [0.19, 1.0, 0.22, 1.0] }}
        onAnimationComplete={(definition) => setHasFocus(definition === 'open')}
      >
        <div
          className={cx('m-0 pb-12', innerClassName)}
          hidden={!isOpen && !hasFocus}
        >
          {children}
        </div>
      </m.div>
    </div>
  )
}

export default Accordion
