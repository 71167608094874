import { CSSProperties } from 'react'
import NextImage from 'next/image'
import cx from 'classnames'

import {
  getImageDimensions,
  getImageSource,
  getSanityImageLoader,
  ImageDimensions,
  LayoutValue,
  ObjectFitValue,
  PhotoImage,
} from '@lib/image'

interface PhotoProps {
  image: PhotoImage
  forceWidth?: number
  forceHeight?: number
  sizes?: string
  layout?: LayoutValue
  objectFit?: ObjectFitValue
  hasPlaceholder?: boolean
  priority?: boolean
  unoptimized?: boolean
  quality?: number
  className?: string
  style?: CSSProperties
}

const Photo = ({
  image,
  forceWidth,
  forceHeight,
  sizes,
  layout = 'intrinsic',
  objectFit,
  hasPlaceholder = true,
  priority = false,
  unoptimized,
  quality = 90,
  className,
  style,
}: PhotoProps) => {
  if (!image || !image.asset) {
    return null
  }

  const source = getImageSource(image)
  const blurDataUrl = (hasPlaceholder && 'lqip' in image ? image : {})?.lqip

  const dimensions: ImageDimensions =
    layout === 'fill' ? {} : getImageDimensions(image, forceWidth, forceHeight)
  const isFixedDimensions = (forceWidth && forceHeight) || image.fixedHeight

  return (
    <figure className={cx(className, 'photo-figure')} style={style}>
      <NextImage
        src={source}
        loader={getSanityImageLoader}
        width={dimensions?.width}
        height={dimensions?.height}
        sizes={sizes}
        quality={quality}
        priority={priority}
        unoptimized={unoptimized}
        layout={isFixedDimensions ? 'fixed' : layout}
        objectFit={objectFit}
        blurDataURL={blurDataUrl}
        placeholder={blurDataUrl ? 'blur' : 'empty'}
        alt={image.alt}
        className="block"
      />
    </figure>
  )
}

export default Photo
