import cx from 'classnames'

import { SanityButtonStyle } from '@data/sanity/queries/types/link'
import { SanityVideo } from '@data/sanity/queries/types/video'

import VideoButton from '@components/buttons/video-button'
import {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'

interface VideoButtonCardProps {
  node: {
    text?: string
    style: SanityButtonStyle
    video: SanityVideo
  }
}

const VideoButtonCard = ({ node }: VideoButtonCardProps) => {
  const {
    video,
    text,
    style: { variant, size, color, icon, iconAlignment, isFullWidth },
  } = node

  return (
    <VideoButton
      video={video}
      variant={getButtonVariant(variant)}
      size={getButtonSize(size)}
      color={getButtonColor(color)}
      icon={icon}
      iconAlignment={getButtonIconAlignment(iconAlignment)}
      className={cx('btn', { 'w-full': isFullWidth })}
    >
      {text}
    </VideoButton>
  )
}

VideoButtonCard.displayName = 'VideoCard'

export default VideoButtonCard
