import {
  AttributeInput,
  CartInput,
  CartLineInput,
  CartLineUpdateInput,
} from '@data/shopify/types/globalTypes'
import {
  cartCreate,
  cartCreateVariables,
  cartCreate_cartCreate_cart,
  cartCreate_cartCreate_cart_lines_edges_node,
} from '@data/shopify/storefront/mutations/types/cartCreate'
import {
  cartLinesAdd,
  cartLinesAddVariables,
  cartLinesAdd_cartLinesAdd_cart,
  cartLinesAdd_cartLinesAdd_cart_lines_edges_node,
} from '@data/shopify/storefront/mutations/types/cartLinesAdd'
import {
  cartLinesUpdate,
  cartLinesUpdateVariables,
  cartLinesUpdate_cartLinesUpdate_cart,
  cartLinesUpdate_cartLinesUpdate_cart_lines_edges_node,
} from '@data/shopify/storefront/mutations/types/cartLinesUpdate'
import {
  cartLinesRemove,
  cartLinesRemoveVariables,
  cartLinesRemove_cartLinesRemove_cart,
  cartLinesRemove_cartLinesRemove_cart_lines_edges_node,
} from '@data/shopify/storefront/mutations/types/cartLinesRemove'
import {
  cartAttributesUpdate,
  cartAttributesUpdateVariables,
  cartAttributesUpdate_cartAttributesUpdate_cart,
} from '@data/shopify/storefront/mutations/types/cartAttributesUpdate'
import {
  cartNoteUpdate,
  cartNoteUpdateVariables,
  cartNoteUpdate_cartNoteUpdate_cart,
} from '@data/shopify/storefront/mutations/types/cartNoteUpdate'
import { getShopAndCart_cart } from '@data/shopify/storefront/queries/types/getShopAndCart'
import {
  CART_ATTRIBUTES_UPDATE,
  CART_CREATE,
  CART_LINES_ADD,
  CART_LINES_REMOVE,
  CART_LINES_UPDATE,
  CART_NOTE_UPDATE,
} from '@data/shopify/storefront/mutations/cart'
import { ShopifyClient } from './client'

export type ShopifyCart =
  | getShopAndCart_cart
  | cartCreate_cartCreate_cart
  | cartLinesAdd_cartLinesAdd_cart
  | cartLinesUpdate_cartLinesUpdate_cart
  | cartLinesRemove_cartLinesRemove_cart
  | cartNoteUpdate_cartNoteUpdate_cart
  | cartAttributesUpdate_cartAttributesUpdate_cart

export type ShopifyCartLine =
  | cartCreate_cartCreate_cart_lines_edges_node
  | cartLinesAdd_cartLinesAdd_cart_lines_edges_node
  | cartLinesUpdate_cartLinesUpdate_cart_lines_edges_node
  | cartLinesRemove_cartLinesRemove_cart_lines_edges_node

export interface CartResponse {
  cart?: ShopifyCart
  error?: string
}

/**
 * Creates a new Shopify cart.
 */
export const createShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartInput: CartInput
): Promise<CartResponse> => {
  try {
    const cartCreateResult = await shopifyStorefrontClient.mutate<
      cartCreate,
      cartCreateVariables
    >({
      mutation: CART_CREATE,
      variables: { cartInput },
    })

    const cart = cartCreateResult.data?.cartCreate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartCreateResult.data?.cartCreate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    return { cart }
  } catch (error) {
    return { error: `${error}` }
  }
}

/**
 * Updates attributes on Shopify cart.
 */
export const updateShopifyCartAttrbites = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  attributes: AttributeInput[]
): Promise<CartResponse> => {
  try {
    const cartAttributesUpdateResult = await shopifyStorefrontClient.mutate<
      cartAttributesUpdate,
      cartAttributesUpdateVariables
    >({
      mutation: CART_ATTRIBUTES_UPDATE,
      variables: { cartId, attributes },
    })

    const cart = cartAttributesUpdateResult.data?.cartAttributesUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartAttributesUpdateResult.data?.cartAttributesUpdate?.userErrors?.[0]
          ?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    return { cart }
  } catch (error) {
    return { error: `${error}` }
  }
}

/**
 * Updates note on Shopify cart.
 */
export const updateShopifyCartNote = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  note: string
): Promise<CartResponse> => {
  try {
    const cartNoteUpdateResult = await shopifyStorefrontClient.mutate<
      cartNoteUpdate,
      cartNoteUpdateVariables
    >({
      mutation: CART_NOTE_UPDATE,
      variables: { cartId, note },
    })

    const cart = cartNoteUpdateResult.data?.cartNoteUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartNoteUpdateResult.data?.cartNoteUpdate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    return { cart }
  } catch (error) {
    return { error: `${error}` }
  }
}

/**
 * Adds new cart line items.
 */
export const addLineItemsToShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lines: CartLineInput[]
): Promise<CartResponse> => {
  try {
    const cartLinesAddResult = await shopifyStorefrontClient.mutate<
      cartLinesAdd,
      cartLinesAddVariables
    >({
      mutation: CART_LINES_ADD,
      variables: { cartId, lines },
    })

    const cart = cartLinesAddResult.data?.cartLinesAdd?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesAddResult.data?.cartLinesAdd?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    return { cart }
  } catch (error) {
    return { error: `${error}` }
  }
}

/**
 * Updates cart line items.
 */
export const updateLineItemsInShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lines: CartLineUpdateInput[]
): Promise<CartResponse> => {
  try {
    const cartLinesUpdateResult = await shopifyStorefrontClient.mutate<
      cartLinesUpdate,
      cartLinesUpdateVariables
    >({
      mutation: CART_LINES_UPDATE,
      variables: { cartId, lines },
    })

    const cart = cartLinesUpdateResult.data?.cartLinesUpdate?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesUpdateResult.data?.cartLinesUpdate?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    return { cart }
  } catch (error) {
    return { error: `${error}` }
  }
}

/**
 * Removes cart line items.
 */
export const removeLineItemsFromShopifyCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string,
  lineIds: string[]
): Promise<CartResponse> => {
  try {
    const cartLinesRemoveResult = await shopifyStorefrontClient.mutate<
      cartLinesRemove,
      cartLinesRemoveVariables
    >({
      mutation: CART_LINES_REMOVE,
      variables: { cartId, lineIds },
    })

    const cart = cartLinesRemoveResult.data?.cartLinesRemove?.cart

    if (!cart) {
      const firstErrorMessage =
        cartLinesRemoveResult.data?.cartLinesRemove?.userErrors?.[0]?.message
      throw new Error(firstErrorMessage ?? 'Unknown error')
    }

    return { cart }
  } catch (error) {
    return { error: `${error}` }
  }
}
