import {
  CartSettings,
  CookieSettings,
  Discount,
  FooterSettings,
  GeneralSettings,
  ProductVariantSelection,
  PromoSettings,
  SeoSettings,
  SocialLink,
  Strings,
} from '@data/sanity/schema'
import { SanityNewsletterBlock } from './blocks'
import { SanityBlogSettings } from './blog'
import { SanityContentFragment } from './content'
import { SanityImageFragment } from './image'
import { SanityLinkFragment, SanityLinkReference } from './link'
import { SanityHasSlug } from './page'
import { SanityProductFragment } from './product'
import { Keyed } from '@lib/helpers'

export type SanityPromo = Pick<
  PromoSettings,
  'enabled' | 'display' | 'text'
> & {
  link?: SanityLinkReference
}

export type SanityMenuLinkFragment = SanityLinkFragment & {
  dropdownItems: Array<SanityLinkFragment>
}

export type SanityMenuFeaturedLinkFragment = SanityMenuLinkFragment & {
  featured?: Array<SanityProductFragment>
}

export interface SanityHeaderDesktopMenu {
  items: Array<SanityMenuFeaturedLinkFragment>
}

export interface SanityHeaderMobileMenu {
  items: Array<SanityMenuLinkFragment>
}

export interface SanityHeaderFragment {
  menuDesktopLeft: SanityHeaderDesktopMenu
  menuDesktopRight: SanityHeaderDesktopMenu
  menuMobilePrimary: SanityHeaderMobileMenu
  menuMobileSecondary: SanityHeaderMobileMenu
  promo: SanityPromo
}

export interface SanityFooterBlockMenu {
  items: Array<SanityLinkFragment>
}

export interface SanityFooterBlock1 {
  title: FooterSettings['blockTitle1']
  description?: Array<SanityContentFragment>
  newsletter: SanityNewsletterBlock
}

export interface SanityFooterBlock2 {
  title: FooterSettings['blockTitle2']
  menu: SanityFooterBlockMenu
}

export interface SanityFooterBlock3 {
  title: FooterSettings['blockTitle3']
  menu: SanityFooterBlockMenu
}

export type SanityFooterSocialLink = Keyed<Pick<SocialLink, 'icon' | 'url'>>

export interface SanityFooterBlock4 {
  title: FooterSettings['blockTitle4']
  description?: Array<SanityContentFragment>
  social?: Array<SanityFooterSocialLink>
}

export interface SanityFooterFragment {
  blocks: [
    SanityFooterBlock1,
    SanityFooterBlock2,
    SanityFooterBlock3,
    SanityFooterBlock4
  ]
  copyright: FooterSettings['copyright']
  paymentMethods?: SanityImageFragment[]
}

export type SanityCart = Pick<
  CartSettings,
  | 'storeURL'
  | 'taxRate'
  | 'message'
  | 'openInSeparatePage'
  | 'showVatId'
  | 'showComment'
> & {
  terms?: Array<SanityContentFragment>
}

export type SanityProductCount = SanityHasSlug & {
  count: number
}

export type SanityCookieConsent = Pick<
  CookieSettings,
  'enabled' | 'message'
> & {
  link?: SanityLinkReference
}

export type SanitySeo = Pick<
  SeoSettings,
  | 'siteTitle'
  | 'metaTitle'
  | 'metaDesc'
  | 'shareTitle'
  | 'shareDesc'
  | 'shareGraphic'
>

export type SanityAccountStrings = Pick<
  Strings,
  | 'account'
  | 'accountAccountDetailsHeading'
  | 'accountViewAddresses'
  | 'accountViewSubscriptions'
  | 'accountDefaultAddressLabel'
  | 'accountAddAddressHeading'
  | 'accountEditAddressHeading'
  | 'accountFirstName'
  | 'accountFirstNamePlaceholder'
  | 'accountFirstNameMissing'
  | 'accountLastName'
  | 'accountLastNamePlaceholder'
  | 'accountLastNameMissing'
  | 'accountCompany'
  | 'accountCompanyPlaceholder'
  | 'accountCompanyMissing'
  | 'accountAddressLine1'
  | 'accountAddressLine1Placeholder'
  | 'accountAddressLine1Missing'
  | 'accountAddressLine2'
  | 'accountAddressLine2Placeholder'
  | 'accountCity'
  | 'accountCityPlaceholder'
  | 'accountCityMissing'
  | 'accountCountry'
  | 'accountCountryPlaceholder'
  | 'accountCountryMissing'
  | 'accountZip'
  | 'accountZipPlaceholder'
  | 'accountZipMissing'
  | 'accountPhone'
  | 'accountPhonePlaceholder'
  | 'accountPhoneMissing'
  | 'accountSetAsDefault'
  | 'addressErrorMessage'
  | 'accountAddAddress'
  | 'accountEditAddress'
  | 'accountProductListHeading'
  | 'accountProductListProduct'
  | 'accountProductListViewProduct'
  | 'accountOrderListHeading'
  | 'accountOrderListOrder'
  | 'accountOrderListDate'
  | 'accountOrderListPaymentStatus'
  | 'accountOrderListFulfillmentStatus'
  | 'accountOrderListTotal'
  | 'accountOrderDetailsHeading'
  | 'accountOrderDetailsDate'
  | 'accountOrderDetailsProduct'
  | 'accountOrderDetailsSku'
  | 'accountOrderDetailsPrice'
  | 'accountOrderDetailsQuantity'
  | 'accountOrderDetailsTotal'
  | 'accountOrderDetailsSubtotal'
  | 'accountOrderDetailsShipping'
  | 'accountOrderDetailsTax'
  | 'accountOrderDetailsBillingAddress'
  | 'accountOrderDetailsShippingAddress'
  | 'accountOrderListPaymentStatusAuthorized'
  | 'accountOrderListPaymentStatusPaid'
  | 'accountOrderListPaymentStatusPartiallyPaid'
  | 'accountOrderListPaymentStatusPartiallyRefunded'
  | 'accountOrderListPaymentStatusPending'
  | 'accountOrderListPaymentStatusRefunded'
  | 'accountOrderListPaymentStatusVoided'
  | 'accountOrderListFulfillmentStatusFulfilled'
  | 'accountOrderListFulfillmentStatusInProgress'
  | 'accountOrderListFulfillmentStatusOnHold'
  | 'accountOrderListFulfillmentStatusOpen'
  | 'accountOrderListFulfillmentStatusPartiallyFulfilled'
  | 'accountOrderListFulfillmentStatusPendingFulfillment'
  | 'accountOrderListFulfillmentStatusRestocked'
  | 'accountOrderListFulfillmentStatusScheduled'
  | 'accountOrderListFulfillmentStatusUnfulfilled'
  | 'accountProductListEmpty'
  | 'accountOrderListEmpty'
>

export interface HasAccountStrings {
  accountStrings: SanityAccountStrings
}

export type SanityAuthStrings = Pick<
  Strings,
  | 'signupFirstName'
  | 'signupFirstNamePlaceholder'
  | 'signupFirstNameMissing'
  | 'signupLastName'
  | 'signupLastNamePlaceholder'
  | 'signupLastNameMissing'
  | 'signupEmail'
  | 'signupEmailPlaceholder'
  | 'signupEmailMissing'
  | 'signupPassword'
  | 'signupPasswordPlaceholder'
  | 'signupPasswordMissing'
  | 'signupErrorMessage'
  | 'signupSubmit'
  | 'loginErrorMessage'
  | 'loginFailedMessage'
  | 'loginSubmit'
  | 'passwordRecoveryErrorMessage'
  | 'passwordRecoverySuccessMessage'
  | 'passwordRecoverySubmit'
>

export interface HasAuthStrings {
  authStrings: SanityAuthStrings
}

export type SanityCollectionStrings = Pick<
  Strings,
  | 'collectionProducts'
  | 'collectionProductCount'
  | 'collectionSortLabel'
  | 'collectionSortDescription'
  | 'collectionShowFiltersLabel'
  | 'collectionFilters'
  | 'collectionRemoveFilter'
  | 'collectionClearAllLabel'
  | 'collectionClearFiltersLabel'
  | 'collectionFilterResultsLabel'
  | 'collectionFilterNoResultsLabel'
  | 'collectionFilterModalClose'
  | 'collectionFilterNoResults'
>

export interface HasCollectionStrings {
  collectionStrings: SanityCollectionStrings
}

export type SanityReviewsStrings = Pick<
  Strings,
  | 'reviewsSingular'
  | 'reviewsPlural'
  | 'reviewsRatingBasedOnSingular'
  | 'reviewsRatingBasedOnPlural'
  | 'reviewsNoReviews'
  | 'reviewsCarouselTitle'
  | 'reviewsVerifiedBuyer'
  | 'reviewsWriteReview'
  | 'reviewsFormAuthorInputLabel'
  | 'reviewsFormAuthorInputPlaceholder'
  | 'reviewsFormAuthorMissing'
  | 'reviewsFormEmailInputLabel'
  | 'reviewsFormEmailInputPlaceholder'
  | 'reviewsFormEmailMissing'
  | 'reviewsFormReviewRatingInputLabel'
  | 'reviewsFormReviewRatingMissing'
  | 'reviewsFormReviewTitleInputLabel'
  | 'reviewsFormReviewTitleInputPlaceholder'
  | 'reviewsFormReviewTitleMissing'
  | 'reviewsFormReviewMessageInputLabel'
  | 'reviewsFormReviewMessageInputPlaceholder'
  | 'reviewsFormReviewMessageMissing'
  | 'reviewsFormSubmit'
  | 'reviewsFormErrorMessage'
  | 'reviewsFormSuccessMessage'
>

export interface HasReviewsStrings {
  reviewsStrings: SanityReviewsStrings
}

export type SanitySiteStrings = Pick<
  Strings,
  | 'loadingPageTitle'
  | 'languageSwitch'
  | 'cartLink'
  | 'featuredProducts'
  | 'skipToContent'
  | 'goHomeLabel'
  | 'mobileMenuLabel'
  | 'productLowStock'
  | 'productOutOfStock'
  | 'productPriceLabel'
  | 'productColorOptionLabel'
  | 'productDiscountText'
  | 'productIncreaseQuantity'
  | 'productDecreaseQuantity'
  | 'productEnterQuantity'
  | 'cartTitle'
  | 'cartClose'
  | 'cartEmpty'
  | 'cartVatSectionTitle'
  | 'cartVatIdInputPlaceholder'
  | 'cartVatIdError'
  | 'cartInvalidVatIdError'
  | 'cartCommentSectionTitle'
  | 'cartCommentInputPlaceholder'
  | 'cartDiscount'
  | 'cartSubtotal'
  | 'cartTotal'
  | 'cartSubmit'
  | 'cartAddToCartErrorMessage'
  | 'carouselCounterText'
  | 'carouselLeftArrowLabel'
  | 'carouselRightArrowLabel'
  | 'carouselDotLabel'
  | 'buttonAddToCart'
  | 'buttonRemove'
  | 'buttonAccept'
  | 'buttonLearnMore'
  | 'buttonTryAgain'
  | 'buttonLoadMore'
  | 'buttonUnavailable'
  | 'buttonWaiting'
  | 'buttonLoading'
  | 'buttonAdding'
  | 'buttonUpdating'
  | 'buttonSubmitting'
  | 'buttonEdit'
  | 'buttonDelete'
  | 'buttonCancel'
  | 'emailAddress'
  | 'emailMissing'
  | 'emailInvalid'
  | 'productWaitlistSubmit'
  | 'productWaitlistSuccess'
  | 'productWaitlistError'
>

export interface SanityIdentity {
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
}

export type PublicSiteSettings = Pick<
  GeneralSettings,
  | 'siteURL'
  | 'klaviyoAccountID'
  | 'gtmContainerId'
  | 'analyticsId'
  | 'facebookEvents'
  | 'facebookPixelId'
  | 'facebookDomainVerification'
  | 'facebookTestEventCode'
  | 'stampedApiKey'
  | 'partnerAdsTracking'
  | 'pageAnimation'
>

export type AllSiteSettings = PublicSiteSettings &
  Pick<GeneralSettings, 'klaviyoPrivateKey' | 'stampedStoreHash'>

export enum SanityDiscountType {
  QUANTITY = 'quantity',
  BUNDLE = 'bundle',
}

export type SanityDiscountProduct = Pick<
  ProductVariantSelection['selection'],
  'id'
> & {
  variantIds: Array<
    ProductVariantSelection['selection']['variants'][number]['id']
  >
}

type SanityPartialDiscount = Pick<
  Discount,
  '_id' | 'title' | 'discountValuePercent'
>

export type SanityQuantityDiscount = SanityPartialDiscount & {
  type: SanityDiscountType.QUANTITY
  minimumQuantity: number
  maximumQuantity: number
}

export type SanityBundleDiscount = SanityPartialDiscount & {
  type: SanityDiscountType.BUNDLE
  products: Array<SanityDiscountProduct>
  doNotStackWithQuantityDiscounts?: boolean
}

export type SanityDiscount = SanityQuantityDiscount | SanityBundleDiscount

export type SanityDiscountQuery = Array<SanityDiscount>

export interface SanitySiteFragment {
  publicLocales: Array<GeneralSettings['locale']>
  settings: PublicSiteSettings
  identity: SanityIdentity
  cart: SanityCart
  blogSettings: SanityBlogSettings | null
  productCounts: Array<SanityProductCount>
  cookieConsent: SanityCookieConsent
  header: SanityHeaderFragment
  footer: SanityFooterFragment
  seo: SanitySeo
  siteStrings: SanitySiteStrings
  discounts: Array<SanityDiscount>
}
