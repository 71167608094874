import groq from 'groq'

export const buttonStyleFragment = groq`
  variant,
  size,
  color,
  icon,
  iconAlignment,
  isFullWidth
`

const buttonFragment = groq`
  isButton,
  isButton == true => {
    "buttonStyles": buttonStyle {
      ${buttonStyleFragment}
    },
  },
`

export const linkPageFragment = groq`
  "id": _id,
  "type": _type,
  "slug": slug.current,
  ${buttonFragment}
`

export const linkFragment = groq`
  _key,
  _type,
  title,
  url,
  ${buttonFragment}
  _type == 'navLanguageSwitch' => {
    displayCurrency
  },
  _type == 'navAccountButton' => {
    text,
    displayIcon
  },
  "page": page->{
    ${linkPageFragment}
  }
`
