import { ButtonHTMLAttributes, forwardRef } from 'react'
import cx from 'classnames'
import { useId } from '@reach/auto-id'

import {
  SanityButtonColor,
  SanityButtonIconAlignment,
  SanityButtonSize,
  SanityButtonVariant,
} from '@data/sanity/queries/types/link'

import Icon, { IconName } from '@components/icon'

export enum ButtonVariant {
  LINK = 'link',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export enum ButtonSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

export enum ButtonColor {
  DEFAULT = 'default',
  WHITE = 'white',
  BLACK = 'black',
  GRAY = 'gray',
  ORANGE = 'orange',
}

export enum ButtonIconAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ButtonIconSize {
  DEFAULT = 'default',
  SMALL = 'small',
  NORMAL = 'normal',
  LARGE = 'large',
}

type VariantColorClassMap = Record<
  ButtonVariant,
  Record<ButtonColor | 'default', string | string[]>
>

export type SizeClassMap = Record<ButtonSize, string | string[]>

export interface ButtonProps {
  variant?: ButtonVariant
  size?: ButtonSize
  color?: ButtonColor
  icon?: IconName
  iconSize?: ButtonIconSize
  iconAlignment?: ButtonIconAlignment
  isActive?: boolean
}

export const getBaseClasses = (variant?: ButtonVariant) =>
  cx(
    'inline-flex items-center disabled:opacity-50',
    {
      'justify-center rounded-full text-center border font-semibold transition-all duration-300':
        variant !== ButtonVariant.LINK,
    },
    {
      'hover:brightness-110': variant === ButtonVariant.PRIMARY,
    }
  )

export const colorClasses: VariantColorClassMap = {
  [ButtonVariant.PRIMARY]: {
    [ButtonColor.WHITE]: ['border-white bg-white text-black'],
    [ButtonColor.BLACK]: ['border-black bg-black text-white'],
    [ButtonColor.GRAY]: ['border-gray bg-gray text-white'],
    [ButtonColor.ORANGE]: ['border-orange bg-orange text-white'],
    [ButtonColor.DEFAULT]: [
      'bg-btn-primary-bg text-btn-primary-text border-btn-primary-border',
      'hover:bg-btn-primary-bg-hover hover:text-btn-primary-text-hover hover:border-btn-primary-border-hover',
    ],
  },
  [ButtonVariant.SECONDARY]: {
    [ButtonColor.WHITE]: [
      'bg-transparent border-white text-white',
      'hover:bg-white hover:text-black',
    ],
    [ButtonColor.BLACK]: [
      'bg-transparent border-black text-black',
      'hover:bg-black hover:text-white',
    ],
    [ButtonColor.GRAY]: [
      'bg-transparent border-gray text-gray',
      'hover:bg-gray hover:text-white',
    ],
    [ButtonColor.ORANGE]: [
      'bg-transparent border-orange text-orange',
      'hover:bg-orange hover:text-white',
    ],
    [ButtonColor.DEFAULT]: [
      'bg-btn-secondary-bg border border-btn-secondary-border text-btn-secondary-text',
      'hover:bg-btn-secondary-bg-hover hover:text-btn-secondary-text-hover hover:border-btn-secondary-border-hover',
    ],
  },
  [ButtonVariant.LINK]: {
    [ButtonColor.WHITE]: ['text-white'],
    [ButtonColor.BLACK]: ['text-black'],
    [ButtonColor.GRAY]: ['text-gray'],
    [ButtonColor.ORANGE]: ['text-orange'],
    [ButtonColor.DEFAULT]: ['text-current'],
  },
}

const sizeClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'text-xs',
  [ButtonSize.NORMAL]: 'text-base',
  [ButtonSize.LARGE]: 'text-2xl',
}

const spacingClasses: SizeClassMap = {
  [ButtonSize.DEFAULT]: '',
  [ButtonSize.SMALL]: 'px-4 py-2',
  [ButtonSize.NORMAL]: 'px-7 py-3',
  [ButtonSize.LARGE]: 'px-7 py-3',
}

export const iconSizeClasses: SizeClassMap = {
  [ButtonIconSize.DEFAULT]: '',
  [ButtonIconSize.SMALL]: 'text-lg',
  [ButtonIconSize.NORMAL]: 'text-xl',
  [ButtonIconSize.LARGE]: 'text-2xl',
}

const activeClasses: VariantColorClassMap = {
  [ButtonVariant.PRIMARY]: {
    [ButtonColor.WHITE]: 'border-white bg-white text-black',
    [ButtonColor.BLACK]: 'border-black bg-black text-white',
    [ButtonColor.GRAY]: 'border-gray bg-gray text-white',
    [ButtonColor.ORANGE]: 'border-orange bg-orange text-white',
    [ButtonColor.DEFAULT]:
      'bg-btn-primary-bg-hover text-btn-primary-text-hover border-btn-primary-border-hover',
  },
  [ButtonVariant.SECONDARY]: {
    [ButtonColor.WHITE]: 'border-white bg-white text-black',
    [ButtonColor.BLACK]: 'border-black bg-black text-white',
    [ButtonColor.GRAY]: 'border-gray bg-gray text-white',
    [ButtonColor.ORANGE]: 'border-orange bg-orange text-white',
    [ButtonColor.DEFAULT]:
      'bg-btn-secondary-bg-hover text-btn-secondary-text-hover border-btn-secondary-border-hover',
  },
  [ButtonVariant.LINK]: colorClasses[ButtonVariant.LINK],
}

export const getButtonVariant = (variant?: SanityButtonVariant) => {
  if (!variant) {
    return
  }

  return variant as ButtonVariant
}

export const getButtonSize = (size?: SanityButtonSize) => {
  if (!size) {
    return ButtonSize.DEFAULT
  }

  return size as ButtonSize
}

export const getButtonColor = (color?: SanityButtonColor) => {
  if (!color) {
    return
  }

  return color as ButtonColor
}

export const getButtonIconAlignment = (
  iconAlignment?: SanityButtonIconAlignment
) => {
  if (!iconAlignment) {
    return
  }

  return iconAlignment as ButtonIconAlignment
}

export const getButtonStyles = ({
  variant = ButtonVariant.LINK,
  color = ButtonColor.DEFAULT,
  size = ButtonSize.NORMAL,
  isActive,
}: ButtonProps) => {
  return cx(
    getBaseClasses(variant),
    colorClasses[variant][color],
    isActive ? activeClasses[variant][color] : '',
    variant !== ButtonVariant.LINK ? spacingClasses[size] : '',
    sizeClasses[size]
  )
}

export interface ButtonIconProps {
  name: IconName
  alignment?: ButtonIconAlignment
  className?: string
}

export const ButtonIcon = ({
  name,
  alignment = ButtonIconAlignment.RIGHT,
  className,
}: ButtonIconProps) => {
  const id = useId()

  return (
    <span
      className={cx(
        {
          'order-first mr-2': alignment === 'left',
          'ml-2': alignment === 'right',
        },
        className
      )}
    >
      <Icon id={`button-icon-${id}`} name={name} />
    </span>
  )
}

const Button = forwardRef<
  HTMLButtonElement,
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onKeyPress,
      id,
      style,
      type,
      'aria-label': ariaLabel,
      variant,
      size,
      color,
      icon,
      iconSize,
      iconAlignment,
      isActive,
    }: ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>,
    ref
  ) => {
    return (
      <button
        type={type}
        id={id}
        ref={ref}
        className={cx(
          getButtonStyles({ variant, size, color, isActive }),
          className
        )}
        onClick={onClick}
        disabled={disabled}
        style={style}
        onKeyPress={onKeyPress}
        aria-label={ariaLabel}
      >
        {children}
        {icon && (
          <ButtonIcon
            name={icon}
            alignment={iconAlignment}
            className={cx(
              iconSize
                ? iconSizeClasses[iconSize]
                : size
                ? iconSizeClasses[size]
                : {}
            )}
          />
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export default Button
