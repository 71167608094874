import groq from 'groq'

import { Preview } from '@lib/sanity/client'
import { getModulesFragment } from './modules'
import { getSiteFragment } from './site'
import { imageFragment } from './image'

export const blogAuthorFragment = groq`
  name,
  "slug": slug.current,
  photo {
    ${imageFragment}
  }
`

export const blogCategoryFragment = groq`
  name,
  "slug": slug.current,
`

export const blogPostOptionsFragment = groq`
  showCoverImage,
  showDate,
  showAuthorName,
  showAuthorPhoto,
  showCategories,
  showExcerpt
`

export const blogPostWithoutContentFragment = groq`
  "date": _updatedAt,
  title,
  "slug": slug.current,
  author->{
    ${blogAuthorFragment}
  },
  categories[]->{
    ${blogCategoryFragment}
  },
  excerpt,
  coverImage {
    ${imageFragment}
  },
`

export const blogSettingsFragment = groq`
  inheritContent,
  sourceLocale
`

export const blogSettingsQuery = groq`
  *[_type == "blogSettings" && locale == $locale][0] {
    ${blogSettingsFragment}
  }
`

export const getBlogPostPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogPost" &&
      type != "template" &&
      slug.current == $slug &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      type,
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "slug": slug.current,
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getBlogPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogPage" &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "posts": *[
        _type == "blogPost" &&
        type != "template" &&
        locale == ^.locale &&
        (!defined(^.author) || author->slug.current == ^.author->slug.current) &&
        (!defined(^.category) || categories[]->slug.current match ^.category->slug.current)
      ] | order(_updatedAt desc) {
        ${blogPostWithoutContentFragment}
      },
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getBlogCategoryPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogCategory" &&
      slug.current == $slug &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "slug": slug.current,
      "posts": *[
        _type == "blogPost" &&
        type != "template" &&
        locale == ^.locale &&
        categories[]->slug.current match ^.slug.current
      ] | order(_updatedAt desc) {
        ${blogPostWithoutContentFragment}
      }
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

export const getBlogAuthorPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "blogAuthor" &&
      slug.current == $slug &&
      locale == $blogLocale
    ] | order(_updatedAt desc)[0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      "slug": slug.current,
      "posts": *[
        _type == "blogPost" &&
        type != "template" &&
        locale == ^.locale &&
        author->slug.current == ^.slug.current
      ] | order(_updatedAt desc) {
        ${blogPostWithoutContentFragment}
      }
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`
