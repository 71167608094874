import { getShop } from '@data/shopify/storefront/queries/types/getShop'
import {
  getShopAndCart,
  getShopAndCartVariables,
} from '@data/shopify/storefront/queries/types/getShopAndCart'
import {
  GET_SHOP,
  GET_SHOP_AND_CART,
} from '@data/shopify/storefront/queries/shop'
import { ShopifyClient } from './client'

export type ShopifyShop = getShop | getShopAndCart

/**
 * Gets Shopify shop information.
 */
export const getShopifyShop = async (
  shopifyStorefrontClient: ShopifyClient
) => {
  try {
    const getCartResult = await shopifyStorefrontClient.query<getShop>({
      query: GET_SHOP,
    })

    if (getCartResult.data) {
      return getCartResult.data
    }
  } catch (error) {
    console.log(error)
  }
}

/**
 * Gets Shopify shop information and cart.
 */
export const getShopifyShopAndCart = async (
  shopifyStorefrontClient: ShopifyClient,
  cartId: string | null
) => {
  if (!cartId) {
    return await getShopifyShop(shopifyStorefrontClient)
  }

  try {
    const getShopResult = await shopifyStorefrontClient.query<
      getShopAndCart,
      getShopAndCartVariables
    >({
      query: GET_SHOP_AND_CART,
      variables: { cartId },
    })

    if (getShopResult.data) {
      return getShopResult.data
    }
  } catch (error) {
    console.log(error)
  }
}
