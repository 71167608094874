import { CSSProperties, ReactNode, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import cx from 'classnames'

interface CheckboxProps {
  id: string
  formRegister: UseFormRegisterReturn
  defaultChecked?: boolean
  errorMessage?: string
  children?: ReactNode
  isInvertedColors?: boolean
  className?: string
}

interface CheckboxSvgProps {
  isChecked: boolean
  className?: string
  pathStyle?: CSSProperties
}

export const CheckboxSvg = ({
  isChecked,
  className,
  pathStyle,
}: CheckboxSvgProps) => (
  <svg
    viewBox="0 0 100 100"
    className={cx(
      'w-[20px] h-[20px] block flex-none mr-3 p-1 pointer-events-none border transition-colors duration-150',
      className
    )}
  >
    <path
      fill="none"
      strokeWidth="13"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      d="M12.1 52.1l24.4 24.4 53-53"
      className={cx(
        'stroke-current text-white',
        'transition-all duration-150 delay-0',
        'stroke-dasharray-120',
        {
          'stroke-dashoffset-120': !isChecked,
          'stroke-dashoffset-0 delay-150': isChecked,
        }
      )}
      style={pathStyle}
    />
  </svg>
)

const Checkbox = ({
  id,
  formRegister,
  defaultChecked,
  errorMessage,
  children,
  className,
  isInvertedColors,
}: CheckboxProps) => {
  const [checked, setChecked] = useState(!!defaultChecked)

  return (
    <div className={cx('relative', className)}>
      <input
        id={`${id}-checkbox`}
        type="checkbox"
        ref={formRegister.ref}
        name={formRegister.name}
        checked={checked}
        onBlur={(event) => {
          formRegister.onBlur(event)
        }}
        onChange={(event) => {
          setChecked(event.target.checked)
          formRegister.onChange(event)
        }}
        className="absolute w-[1px] h-[1px] p-0 m-[-1px] overflow-hidden whitespace-nowrap border-none opacity-0"
      />
      <label
        htmlFor={`${id}-checkbox`}
        className="relative inline-flex transform-none top-auto left-auto mx-auto p-0 cursor-pointer"
      >
        <CheckboxSvg
          isChecked={checked}
          className={cx({
            'border-input-border': !isInvertedColors,
            'border-input-inverted-border': isInvertedColors,
            'delay-150': !checked,
            'bg-black delay-0': checked,
          })}
        />
        <div className="flex items-center">{children}</div>
      </label>
      {errorMessage && (
        <span role="alert" className="block mt-1 text-xs text-error">
          {errorMessage}
        </span>
      )}
    </div>
  )
}

export default Checkbox
