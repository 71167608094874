import {
  HTMLAttributes,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useContext,
} from 'react'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classnames'

import { SanityLink } from '@data/sanity/queries/types/content'
import { useLogoutUser } from '@lib/auth'
import { getPageUrl, isRouteCollection, PageType } from '@lib/routes'
import { SiteContext } from '@lib/site'

import Button, {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import ButtonLink from '@components/buttons/button-link'

interface LinkProps {
  link: SanityLink
  onClick?: () => void
  children?: ReactNode
  showCollectionCount?: boolean
}

const Link = ({
  link,
  children,
  tabIndex,
  onClick,
  className,
  showCollectionCount,
  'aria-label': ariaLabel,
}: LinkProps & HTMLAttributes<HTMLAnchorElement>) => {
  const { getProductCount } = useContext(SiteContext)
  const router = useRouter()
  const logoutUser = useLogoutUser()

  const pageType = link.page && (link.page.type as PageType)
  const pageSlug = link.page?.slug
  const isCollection = !!pageType && isRouteCollection(pageType)
  const collectionSlug = isCollection && pageSlug ? pageSlug : 'all'
  const collectionCount = getProductCount(collectionSlug)

  const { url, isButton, buttonStyles } = link

  // External link
  if (url) {
    return (
      <ButtonLink
        href={url}
        target={url && !url.match('^mailto:') ? '_blank' : '_self'}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
        rel="noopener noreferrer"
        variant={getButtonVariant(buttonStyles?.variant)}
        size={getButtonSize(buttonStyles?.size)}
        color={getButtonColor(buttonStyles?.color)}
        icon={buttonStyles?.icon}
        iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
        className={cx(
          { btn: isButton, 'w-full': buttonStyles?.isFullWidth },
          className
        )}
      >
        {children}
      </ButtonLink>
    )
  }

  // Internal Page
  if (!pageType) {
    return null
  }

  const pageUrl = getPageUrl(pageType, pageSlug)

  const getClickHandler = (event: MouseEvent<HTMLButtonElement>) => {
    if (pageType === PageType.LOGOUT_PAGE) {
      event.preventDefault()
      logoutUser(() => {
        router.push(getPageUrl(PageType.LOGIN_PAGE))
      })
    }

    onClick && onClick()
  }

  const getKeyPressHandler = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (pageType === PageType.LOGOUT_PAGE) {
      event.preventDefault()
      logoutUser(() => {
        router.push(getPageUrl(PageType.LOGIN_PAGE))
      })
    }

    onClick && onClick()
  }

  return (
    <NextLink href={pageUrl}>
      <Button
        role="link"
        tabIndex={tabIndex}
        onClick={getClickHandler}
        onKeyPress={getKeyPressHandler}
        aria-label={ariaLabel}
        variant={getButtonVariant(buttonStyles?.variant)}
        size={getButtonSize(buttonStyles?.size)}
        color={getButtonColor(buttonStyles?.color)}
        icon={buttonStyles?.icon}
        iconAlignment={getButtonIconAlignment(buttonStyles?.iconAlignment)}
        className={cx(
          { btn: isButton, 'w-full': buttonStyles?.isFullWidth },
          className
        )}
      >
        {children}

        {showCollectionCount && isCollection && (
          <span
            aria-hidden="true"
            className="inline-block relative ml-2 leading-none align-super text-[.5em]"
          >
            {collectionCount}
          </span>
        )}
      </Button>
    </NextLink>
  )
}

export default Link
