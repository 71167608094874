import groq from 'groq'

import { Preview } from '@lib/sanity/client'
import { getModulesFragment } from './modules'
import { getSiteFragment } from './site'
import { imageFragment } from './image'
import { contentFragment } from './content'

const productPhotosFragment = groq`
  "main": galleryPhotos[] {
    forOption,
    photos[] {
      ${imageFragment}
    }
  },
  "listing": listingPhotos[] {
    forOption,
    "default": listingPhoto {
      ${imageFragment}
    },
    "hover": listingPhotoHover {
      ${imageFragment}
    }
  }
`

const productVariantFragment = groq`
  sku,
  barcode,
  title,
  price,
  comparePrice,
  inStock,
  lowStock,
  seo,
  options[] {
    name,
    position,
    value
  },
  "id": variantID
`

export const getProductFragment = (preview: Preview) => groq`
  type,
  sku,
  barcode,
  title,
  price,
  comparePrice,
  description[] {
    ${contentFragment}
  },
  inStock,
  lowStock,
  useGallery,
  surfaceOption,
  options[] {
    name,
    position,
    values[],
  },
  optionSettings[] {
    forOption,
    "color": color->color {
      hex,
    },
  },
  optionNames[] {
    forOption,
    name,
  },
  "createdAt": _createdAt,
  "slug": slug.current,
  "id": productID,
  "photos": {
    ${productPhotosFragment}
  },
  "variants": *[
    _type == "productVariant" &&
    parentId == ^._id &&
    !wasDeleted
    ${preview.active ? '' : '&& !isDraft'}
  ] {
    ${productVariantFragment}
  },
  "filters": filters[] {
    "slug": filter->slug.current,
    forOption,
  },
`

export const getProductPageQuery = (preview: Preview) => groq`
  {
    "page": *[
      _type == "product" &&
      slug.current == $slug &&
      locale == $locale &&
      !wasDeleted
      ${preview.active ? '' : '&& !isDraft'}
    ] | order(_updatedAt desc)[0] {
      hasTransparentHeader,
      seo,
      noIndex,
      modules[] {
        ${getModulesFragment(preview)}
      },
      privateModules[] {
        ${getModulesFragment(preview)}
      },
      "product": {
        ${getProductFragment(preview)}
      }
    },
    "site": {
      ${getSiteFragment(preview)}
    }
  }
`

const productMinimalContentFragment = groq`
  _id,
  locale,
  description[] {
    ${contentFragment}
  },
  modules[]
`

export const productsByShopifyIdQuery = groq`
  *[
    _type == "product" &&
    productID == $shopifyProductId
  ] {
    ${productMinimalContentFragment}
  }
`

const variantQueryFragment = groq`
  "product": *[
    _type == "product" &&
    _id == ^.parentId
  ][0] {
    "id": productID,
    title,
    "slug": slug.current,
  },
  "id": variantID,
  title,
  price,
  sellingPlanId,
  "photos": {
    "cart": *[
      _type == "product" &&
      _id == ^.parentId
    ][0].cartPhotos[] {
      forOption,
      "default": cartPhoto {
        ${imageFragment}
      },
    }
  },
  options[] {
    name,
    position,
    value
  }
`

export const productVariantQuery = groq`
  *[
    _type == "productVariant" &&
    variantID == $shopifyProductVariantId &&
    locale == $locale
  ][0] {
    ${variantQueryFragment}
  }
`

export const productVariantsQuery = groq`
  *[
    _type == "productVariant" &&
    variantID in $shopifyProductVariantIds &&
    locale == $locale
  ] {
    ${variantQueryFragment}
  }
`

export const productVariantsByShopifyIdQuery = groq`
  *[
    _type == "productVariant" &&
    productID == $shopifyProductId
  ] {
    _id,
    variantID
  }
`

export const productCatalogueQuery = groq`
  *[
    _type == "product" &&
    locale == $locale &&
    !wasDeleted &&
    !isDraft
  ] {
    type,
    title,
    "id": productID,
    "slug": slug.current,
    "subscriptionProductIds": subscriptionProducts[]->productID
  }
`

export const productExistsQuery = groq`
  *[
    _type == "product" &&
    _id == $id
  ][0] {
    _id
  }
`
